import { createIconSetFromIcoMoon } from '@expo/vector-icons';
import { useFonts } from 'expo-font';

const getIcoMoonFont = () => {
  
  const Icon = createIconSetFromIcoMoon(
    require('./selection.json'),
    'IcoMoon',
    'icomoon.ttf'
  );

  const [fontsLoaded] = useFonts({ IcoMoon: require('./icomoon.ttf') });

  return {Icon, fontsLoaded};
}

export default getIcoMoonFont;
