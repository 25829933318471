import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import getIcoMoonFont from '../assets/icomoon/icomoon';
import { useAppSelector } from '../redux/store';

interface SearchBarProps {
  openSearchModal: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ openSearchModal }) => {
  const text = useAppSelector(state => state.treed.searchText);
  const { Icon, fontsLoaded } = getIcoMoonFont();

  if (!fontsLoaded) {
    return (<View><Text>Loading...</Text></View>)
  }

  return (
    <React.Fragment>
      <TouchableOpacity style={styles.searchContainer} onPress={openSearchModal}>
        <View style={styles.input} >
          <Text>{text}</Text>
        </View>
        <Icon name='search' size={21} color='#6f6f71' style={styles.search} />
      </TouchableOpacity>
    </React.Fragment>
  );
}

const styles = StyleSheet.create({
  searchContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 24,
    marginBottom: 8,
    paddingHorizontal: 20,
  },
  input: {
    flex: 1,
    height: 36,
    borderRadius: 4,
    backgroundColor: '#f2f2f5',
    paddingHorizontal: 40,
    justifyContent: 'center',
  },
  search: {
    position: 'absolute',
    left: 32,
  },
  filterButton: {
    position: 'absolute',
    right: 32,
    paddingLeft: 4,
    borderLeftColor: '#6f6f71',
    borderLeftWidth: 1,
    borderStyle: 'solid',
  }
});

export default SearchBar;
