import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import getIcoMoonFont from '../assets/icomoon/icomoon';
import { useAppSelector } from '../redux/store';

interface FilterButtonProps {
  goToFilter: () => void
};

const FilterButton: React.FC<FilterButtonProps> = ({ goToFilter }) => {
  const { Icon, fontsLoaded } = getIcoMoonFont();
  const [filtered, setFiltered] = useState(false);

  const {
    minPrice,
    maxPrice,
    bedroom,
    bathroom,
    den,
    parking } = useAppSelector(state => state.listings);

  useEffect(() => {
    const isMinPriceSet = minPrice > 0;
    const isMaxPriceSet = maxPrice < 2000000;
    const isBedroomSet = bedroom ? bedroom >= 0 : false;
    const isBathroomSet = bathroom ? bathroom > 0 : false;
    const isParkingSet = parking ? parking > 0 : false;
    const isDenSet = den ? den : false;
    setFiltered(isMinPriceSet || isMaxPriceSet || isBedroomSet || isBathroomSet || isParkingSet || isDenSet);
  }, [minPrice, maxPrice, bedroom, bathroom, den, parking]);

  if (!fontsLoaded) {
    return (<View><Text>Loading...</Text></View>)
  }

  return (
    <View style={styles.buttonContainer}>
      <TouchableOpacity style={StyleSheet.flatten([styles.filterButton, { borderColor: filtered ? '#1c1c1e' : '#dadadd' }])} onPress={goToFilter}>
        <Text>Filter</Text>
        <Icon name='arrowDown' size={12} color='#1c1c1e' />
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  buttonContainer: {
    paddingHorizontal: 20,
    marginBottom: 20,
  },
  filterButton: {
    height: 28,
    width: 74,
    borderRadius: 14,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderWidth: 1,
    gap: 8
  },
});

export default FilterButton;