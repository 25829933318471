import { useLinkProps } from '@react-navigation/native';
import React from 'react';
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import getIcoMoonFont from '../assets/icomoon/icomoon';
// @ts-ignore
import Cookies from 'js-cookie';
import { setCurrentUser } from '../redux/actions';
import { useAppDispatch } from '../redux/store';

interface AccountProp {
  toggleOverlay: () => void;
}
const Account: React.FC<AccountProp> = ({ toggleOverlay }) => {
  const dispatch = useAppDispatch();
  const { onPress } = useLinkProps({ to: { screen: 'Profile' } });
  const { Icon, fontsLoaded } = getIcoMoonFont();

  if (!fontsLoaded) {
    return (<View><Text>Loading...</Text></View>)
  }

  const signout = () => {
    Cookies.remove('treedRefreshToken');
    Cookies.remove('treedTimestamp');
    Cookies.remove('treedUser');
    dispatch(setCurrentUser(undefined));
    toggleOverlay();
  };

  return (
    <ScrollView style={{ backgroundColor: '#f9f9f9' }}>
      <View style={{ display: 'flex', alignItems: 'flex-end', paddingVertical: 15, paddingHorizontal: 20, borderColor: '#dadadd', borderWidth: 1 }}>
        <TouchableOpacity onPress={toggleOverlay}>
          <Icon name='close' size={14} color='#1c1c1e' />
        </TouchableOpacity>
      </View>
      <View>
        <TouchableOpacity onPress={() => { onPress(); toggleOverlay(); }}>
          <View style={styles.option}>
            <Text style={{ fontSize: 15, color: '#1c1c1e', fontWeight: '600' }}>My account</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={signout}>
          <View style={styles.option}>
            <Text style={{ fontSize: 15, color: '#378c79', fontWeight: '600' }}>Sign out</Text>
          </View>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  option: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 52,
    borderBottomColor: '#dadadd',
    borderBottomWidth: 1,
    paddingHorizontal: 20
  }
});

export default Account;
