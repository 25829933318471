import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TreedState {
  searchText: string,
};

const initialState = { searchText: '' } as TreedState;

const treedSlice = createSlice({
  name: 'treed',
  initialState,
  reducers: {
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    clearSearchText: (state) => {
      state.searchText = '';
    }
  },
});

export const { setSearchText, clearSearchText } = treedSlice.actions;
export default treedSlice.reducer;
