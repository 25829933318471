import { addListener, createListenerMiddleware, isAnyOf, TypedAddListener, TypedStartListening } from '@reduxjs/toolkit';
import { applyFilter, setFilterBathOption, setFilterBedOption, setFilterMaxPrice, setFilterMinPrice, setFilterParkingOption } from './actions';
import type { AppDispatch, RootState } from './store';


export const listenerMiddleware = createListenerMiddleware()

export type AppStartListening = TypedStartListening<RootState, AppDispatch>

export const startAppListening =
  listenerMiddleware.startListening as AppStartListening

export const addAppListener = addListener as TypedAddListener<
  RootState,
  AppDispatch
>

startAppListening({
  matcher: isAnyOf(setFilterBathOption, setFilterBedOption, setFilterMaxPrice, setFilterMinPrice, setFilterParkingOption),
  effect: async (action, listenApi) => {
    listenApi.dispatch(applyFilter());
  },
});