// import axios from 'axios';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Dimensions, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import getIcoMoonFont from '../assets/icomoon/icomoon';
import Agent from '../components/agent';
import Client from '../components/client';
import Create from '../components/create';
import Login from '../components/login';
import Role from '../components/role';
import { setAccountBrokerage, setAccountEmail, setAccountFirstName, setAccountLastName, setAccountLicense, setAccountPassword, setAccountPhone } from '../redux/actions';
import { useAppDispatch, useAppSelector } from '../redux/store';
import type { TreedStackScreenProps } from '../types';

const { height } = Dimensions.get('window');

const Account: React.FC<TreedStackScreenProps<'Signup'>> = ({ navigation }) => {
  const [current, setCurrent] = useState(0);
  const account = useAppSelector(state => state.account);
  const dispatch = useAppDispatch();

  const { Icon, fontsLoaded } = getIcoMoonFont();

  const nextPage = () => {
    setCurrent(current + 1);
  };

  const toClientCreation = () => {
    setCurrent(2);
  };

  const toAgentCreation = () => {
    setCurrent(3);
  };

  const toRoleSelection = () => {
    setCurrent(1);
  };

  const toLogin = () => {
    setCurrent(4);
  };

  const toSignup = () => {
    setCurrent(0);
  }

  const toMain = () => {
    navigation.navigate('Treed');
  }

  const createAccount = async () => {
    const url = `${axios.defaults.baseURL}/api/users/`;
    try {
      await axios.post(url, {
        email: account.email,
        password: account.password,
        first_name: account.firstName,
        last_name: account.lastName,
        brokerage: account.brokerage,
        license: account.license,
        phone: account.phone,
      });
      toLogin();
    } catch (err) {
      console.log(err);
    }
  };

  const reset = () => {
    dispatch(setAccountEmail(''));
    dispatch(setAccountFirstName(''));
    dispatch(setAccountLastName(''));
    dispatch(setAccountPassword(''));
    dispatch(setAccountBrokerage(''));
    dispatch(setAccountLicense(''));
    dispatch(setAccountPhone(''));
  };

  useEffect(() => {
    reset();
  }, [])

  const renderBody = () => {
    let body;
    switch (current) {
      case 0:
        body = <Create nextPage={nextPage} toLogin={toLogin} />
        break;
      case 1:
        body = <Role createClient={toClientCreation} createAgent={toAgentCreation} />
        break;
      case 2:
        body = <Client toRoleSelection={toRoleSelection} createAccount={() => createAccount()} />
        break;
      case 3:
        body = <Agent toRoleSelection={toRoleSelection} createAccount={() => createAccount()} />
        break;
      case 4:
        body = <Login toSignup={toSignup} toMain={toMain} />
        break;
    }

    return body;
  }

  if (!fontsLoaded) {
    return (<View><Text>Loading...</Text></View>)
  }

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <TouchableOpacity onPress={() => { navigation.navigate('Treed') }}><Icon name='TreedLogoText' size={24} color="#378c79" /></TouchableOpacity>
      </View>
      <ScrollView style={styles.content}>
        {renderBody()}
      </ScrollView>
    </View >
  );
}

const styles = StyleSheet.create({
  container: {
    height: height,
    width: '100%',
    backgroundColor: '#f9f9f9',
  },
  header: {
    height: 64,
    borderBottomColor: '#dadadd',
    borderBottomWidth: 1,
    justifyContent: 'center',
    padding: 20
  },
  content: {
    paddingHorizontal: 20,
    paddingVertical: 32
  }
});

export default Account;
