/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { TreedStackParamList } from '../types';

const linking: LinkingOptions<TreedStackParamList> = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Treed: {},
      Signup: {
        path: 'signup'
      },
      Project: 'project/:id',
      Listing: 'listing/:id',
      Filter: 'filter',
      Search: 'search',
      Share: 'listing/:id/share/',
      Profile: 'Profile',
    },
  },
};

export default linking;
