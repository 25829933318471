import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

const MAX_PRICE = 2000000;

interface Project {
  id: number;
};

interface Listing {
  id: number;
  listing_price: number;
  bedroom: number;
  bathroom: number;
  parking: number;
  den: number;
  project: Project;
};

interface ListingState {
  listings: Listing[],
  filteredListings: Listing[],
  minPrice: number,
  maxPrice: number,
  bedOption?: string,
  bathOption?: string,
  parkingOption?: string,
  bedroom?: number,
  bathroom?: number,
  den?: boolean,
  parking?: number,
  selected?: number,
};

const emptyListing: Listing[] = []

const initialState = { listings: emptyListing, filteredListings: emptyListing, minPrice: 0, maxPrice: MAX_PRICE } as ListingState

export const fetchListings = createAsyncThunk
  (
    'listing/fetchListings',
    async (thunkAPI) => {
      const url = `https://www.treedrealestate.com/api/listings/`;
      try {
        const response = await axios.get(url);
        return response.data;
      } catch (err) {
        console.log(err)
      }
    }
  )


const listingSlice = createSlice({
  name: 'listing',
  initialState,
  reducers: {
    selectListing: (state, action: PayloadAction<number>) => {
      state.selected = action.payload;
    },
    deselectListing: (state) => {
      state.selected = undefined;
    },
    setFilterMinPrice: (state, action: PayloadAction<number>) => {
      state.minPrice = action.payload;
    },
    setFilterMaxPrice: (state, action: PayloadAction<number>) => {
      state.maxPrice = action.payload;
    },
    setFilterBedOption: (state, action: PayloadAction<string | undefined>) => {
      const bed = action.payload;
      state.bedOption = bed;
      if (bed === "Studio") {
        state.bedroom = 0;
      } else {
        state.bedroom = bed ? parseInt(bed) : undefined;
      }
      state.den = bed ? bed !== "Studio" && bed.length > 1 : undefined;
    },
    setFilterBathOption: (state, action: PayloadAction<string | undefined>) => {
      const bath = action.payload;
      state.bathOption = bath;
      state.bathroom = bath ? parseInt(bath) : undefined;
    },
    setFilterParkingOption: (state, action: PayloadAction<string | undefined>) => {
      const parking = action.payload;
      state.parkingOption = action.payload;
      state.parking = parking ? parseInt(parking) : undefined;
    },
    applyFilter: (state) => {
      const candidate = (l: Listing) => {
        const minPriceCondition = l.listing_price > state.minPrice;
        const maxnPriceCondition = state.maxPrice === MAX_PRICE ? true : l.listing_price <= state.maxPrice;
        const bedroomCondition = state.bedroom
          ? l.bedroom === state.bedroom
          : true;
        const bathroomCondition = state.bathroom
          ? l.bathroom === state.bathroom
          : true;
        const denCondition = state.den ? l.den > 1 : true;
        const parkingCondition = state.parking ? l.parking === state.parking : true;
        return minPriceCondition && maxnPriceCondition && bedroomCondition && bathroomCondition && denCondition && parkingCondition;
      }
      state.filteredListings = state.listings.filter((l) => {
        return candidate(l);
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchListings.fulfilled, (state, action) => {
      const listings = action.payload;
      state.listings = listings;
      state.filteredListings = listings;
    })
  },
});

export const { applyFilter, selectListing, deselectListing, setFilterMinPrice, setFilterMaxPrice, setFilterBedOption, setFilterBathOption, setFilterParkingOption } = listingSlice.actions;
export default listingSlice.reducer;