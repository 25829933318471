import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import Email from '../components/Email';
import Share from '../components/Share';
import type { TreedStackScreenProps } from '../types';

interface IAgent {
  id: number;
  name: string;
  phone: string;
  email: string;
};

interface IProject {
  name: string;
  address: string;
  image: string;
};

interface IListing {
  id: number;
  agent: IAgent;
  balcony: string;
  bathroom: number;
  bedroom: number;
  current_deposit: number;
  den: number;
  description: string;
  exposure: string;
  future_deposit: number;
  listing_price: number;
  locker: number;
  maintenance_fee: number;
  parking: number;
  project: IProject;
  purchase_price: number;
  size: string;
  unit: string;
};

const ShareModalScreen: React.FC<TreedStackScreenProps<"Share">> = ({ navigation, route }) => {
  const [screen, setScreen] = useState(0);
  const [listing, updateListing] = useState<IListing | null>(null);
  const listingID = parseInt(route.params.id, 10);

  useEffect(() => {
    const getListing = async (listingID: number) => {
      const resp = await axios.get(`${axios.defaults.baseURL}/api/listings/${listingID}/`);
      const data = resp.data;
      console.log(data);
      updateListing(data);
    };
    if (listingID > 0) {
      getListing(listingID);
    }
  }, [listingID]);

  if (listingID < 0 || !listing) {
    return (<View><Text>Loading...</Text></View>)
  };

  const goBack = () => {
    navigation.navigate('Listing', { id: route.params.id });
  };

  return (
    <>
      {
        screen == 0 ?
          <Share goBack={goBack} listing={listing} uri={`${axios.defaults.baseURL}${listing.project.image}`} toEmail={() => setScreen(1)} /> :
          <Email goBack={goBack} listing={listing} uri={`${axios.defaults.baseURL}${listing.project.image}`} />
      }
    </>
  );
}

export default ShareModalScreen;
