import axios from 'axios';
// @ts-ignore
import Cookies from 'js-cookie';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { ToastProvider } from 'react-native-toast-notifications';
import { Provider } from 'react-redux';
import { store } from './redux/store';

import getIcoMoonFont from './assets/icomoon/icomoon';
import Navigation from './navigation';

// set scrf token from django cookie
const csrftoken = Cookies.get('csrftoken');
axios.defaults.headers.common['X-CSRFToken'] = csrftoken;

// define a default serializer function to serialize an array into url params
// @ts-ignore
axios.defaults.paramsSerializer = function (params) {
  if (params.fields) {
    const encodedParams = btoa(JSON.stringify(params.fields));
    return `fields=${encodedParams}`;
  }
};

axios.defaults.baseURL = 'https://www.treedrealestate.com';

const App = () => {

  const { Icon, fontsLoaded } = getIcoMoonFont();

  if (!fontsLoaded) {
    return null;
  } else {
    return (
      <Provider store={store}>
        <ToastProvider
          icon={<Icon name='success' size={17} color='#fff' />}
          offset={30}
        >
          <SafeAreaProvider>
            <Navigation />
          </SafeAreaProvider>
        </ToastProvider>
      </Provider>
    );
  }
}

export default App;
