import * as Clipboard from 'expo-clipboard';
import React from 'react';
import { Dimensions, Image, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useToast } from "react-native-toast-notifications";
import NumberFormat from 'react-number-format';
import getIcoMoonFont from '../assets/icomoon/icomoon';

interface IAgent {
  id: number;
  name: string;
  phone: string;
  email: string;
};

interface IProject {
  name: string;
  address: string;
  image: string;
};

interface IListing {
  id: number;
  agent: IAgent;
  balcony: string;
  bathroom: number;
  bedroom: number;
  current_deposit: number;
  den: number;
  description: string;
  exposure: string;
  future_deposit: number;
  listing_price: number;
  locker: number;
  maintenance_fee: number;
  parking: number;
  project: IProject;
  purchase_price: number;
  size: string;
  unit: string;
};

interface ShareProp {
  goBack: () => void;
  toEmail: () => void;
  listing: IListing;
  uri: string;
};


const { height } = Dimensions.get('window');

const Share: React.FC<ShareProp> = ({ goBack, toEmail, listing, uri }) => {
  const toast = useToast();

  const { Icon, fontsLoaded } = getIcoMoonFont();

  if (!fontsLoaded) {
    return (<View><Text>Loading...</Text></View>)
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={{ fontSize: 17 }}>Share</Text>
        <TouchableOpacity onPress={goBack}>
          <Icon name='close' size={15} color='#1c1c1e' />
        </TouchableOpacity>
      </View>
      <ScrollView style={{ padding: 24 }}>
        <View style={{ borderBottomColor: '#dadadd', borderBottomWidth: 1, paddingBottom: 24 }}>
          <Text style={{ fontSize: 21, color: '#1c1c1e', marginBottom: 24 }}>Share this listing with friends and agents</Text>
          <View>
            <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 16, gap: 12 }}>
              <View style={{ width: 77, height: 68, borderRadius: 2 }}>
                {
                  listing.project.image ?
                    <Image
                      style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: 2,
                      }}
                      source={{ uri: uri }} /> :
                    <View style={{
                      display: 'flex',
                      width: '100%',
                      height: '100%',
                      backgroundColor: '#b7b7b9',
                      borderRadius: 4,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                      <Icon name='noImage' size={40} style={{ color: '#dadadd' }} />
                    </View>
                }
              </View>
              <View style={{ display: 'flex', gap: 16, justifyContent: 'center' }}>
                <Text style={{ color: '#1c1c1e', fontSize: 17, fontWeight: '600' }}>{listing.project.name}</Text>
                <Text style={{ color: '#97979a', fontSize: 15 }}>{listing.project.address}</Text>
              </View>
            </View>
            <View>
              <Text style={{ color: '#1c1c1e', fontSize: 13, marginBottom: 4 }}>Unit. {listing.unit}</Text>
              <Text style={{ color: '#1c1c1e', fontSize: 17, fontWeight: '600', marginBottom: 8 }}>
                <NumberFormat
                  value={listing.listing_price}
                  thousandSeparator={true}
                  prefix={'$'}
                  decimalScale={0}
                  displayType={'text'} />
              </Text>
              <View style={{ display: 'flex', flexDirection: 'row', gap: 40 }}>
                <Text style={styles.roomText}>
                  <Icon name='bed' style={styles.icon} />
                  {listing.bedroom}
                </Text>
                <Text style={styles.roomText}>
                  <Icon name='bath' style={styles.icon} />
                  {listing.bathroom}
                </Text>
                <Text style={styles.roomText}>
                  <Icon name='park' style={styles.icon} />
                  {listing.parking}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{ marginTop: 24, display: 'flex', gap: 16 }}>
          <TouchableOpacity
            onPress={() => {
              Clipboard.setStringAsync(`http://localhost:19006/listing/${listing.id}/`);
              toast.show('Link copied');
            }}
          >
            <View style={styles.actionButton}>
              <Text>Copy Link</Text>
              <Icon name='copy' color='#1c1c1e' size={21} />
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={toEmail}>
            <View style={styles.actionButton}>
              <Text>Email</Text>
              <Icon name='email' color='#1c1c1e' size={21} />
            </View>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </View>
  )
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F9F9F9',
    height: height,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: 64,
    borderBottomWidth: 1,
    borderBottomColor: '#dadadd',
    paddingHorizontal: 20,
  },
  icon: {
    fontSize: 15,
    marginRight: 8,
    color: '#1c1c1e',
  },
  roomText: {
    fontSize: 15,
    color: '#1c1c1e',
  },
  actionButton: {
    borderColor: '#dadadd',
    height: 48,
    width: '100%',
    borderRadius: 4,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    shadowColor: '#171717',
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 1,
  }
});

export default Share;
