import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

interface SignupProps {
  goToSignup: () => void;
}

const Signup: React.FC<SignupProps> = ({ goToSignup }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.header}>Find your place of dream</Text>
      <Text style={styles.body}>Try it! Treed provides various functions for your convenience. Also, more features will come in the near future</Text>
      <TouchableOpacity style={styles.button} onPress={goToSignup}>
        <Text style={styles.buttonText}>Sign up</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingHorizontal: 20,
    paddingVertical: 24,
    backgroundColor: '#2a3734',
    borderRadius: 8,
    marginBottom: 32,
  },
  header: {
    fontSize: 21,
    color: '#fff',
    fontWeight: "600",
  },
  body: {
    fontSize: 15,
    lineHeight: 22,
    color: '#fff',
    marginTop: 16,
    marginBottom: 24,
  },
  button: {
    width: 98,
    height: 48,
    backgroundColor: '#fff',
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    fontSize: 15,
    fontWeight: '600',
    color: '#1c1c1e'
  }
});

export default Signup;
