import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { setAccountEmail } from '../redux/actions';
import { useAppDispatch } from '../redux/store';

interface CreateProps {
  nextPage: () => void;
  toLogin: () => void;
}

const Create: React.FC<CreateProps> = ({ nextPage, toLogin }) => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setAccountEmail(''));
  }, []);

  const process = async () => {
    const url = `https://www.treedrealestate.com/api/users/exists?username=${email}`;
    try {
      await axios.get(url);
      dispatch(setAccountEmail(email));
      nextPage();
    } catch (err) {
      const message = (err as Error).message;
      if (message.includes('400')) {
        setErrorMessage('Please provide a valid email address');
      } else if (message.includes('409')) {
        setErrorMessage('This email already exists');
      }
    }
  };

  return (
    <View>
      <Text style={textStyles.title}>Create Account</Text>
      <TextInput
        autoFocus={true}
        placeholder={'Email address'}
        placeholderTextColor="#97979a"
        style={
          StyleSheet.flatten([
            styles.input,
            { borderColor: errorMessage ? '#b82e14' : '#dadadd' },
            Platform.OS == 'web' && { outlineStyle: 'none' }
          ])
        }
        onChangeText={(t) => {
          if (errorMessage) {
            setErrorMessage('');
          }
          setEmail(t);
        }}
        onSubmitEditing={process}
        value={email}
      />
      {errorMessage !== '' && <Text style={textStyles.error}>{errorMessage}</Text>}
      <View style={styles.login}>
        <Text style={textStyles.label}>Already have an account?</Text>
        <TouchableOpacity onPress={toLogin}><Text style={{ fontWeight: '600' }}>Log in</Text></TouchableOpacity>
      </View>
      <TouchableOpacity onPress={process} style={styles.button}>
        <Text style={textStyles.button}>Next</Text>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  input: {
    width: '100%',
    height: 60,
    paddingHorizontal: 16,
    backgroundColor: '#fff',
    borderRadius: 4,
    borderWidth: 1,
    border: '1px solid #dadadd',
    marginTop: 32,
  },
  login: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    marginTop: 48
  },
  button: {
    width: 100,
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-end',
    backgroundColor: '#378c79',
    borderRadius: 4,
    marginTop: 32,
  }
});

const textStyles = StyleSheet.create({
  title: {
    fontSize: 21,
    fontWeight: '600',
    color: '#1c1c1e'
  },
  input: {
    fontSize: 17,
    color: '#97979a',
  },
  button: {
    fontSize: 16,
    fontWeight: '600',
    color: '#fff'
  },
  label: {
    fontSize: 14,
    color: '#1c1c1e'
  },
  error: {
    fontSize: 15,
    color: '#b82e14',
    paddingTop: 8
  }
});

export default Create;
