import { Slider } from '@miblanchard/react-native-slider';
import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import NumberFormat from 'react-number-format';
import getIcoMoonFont from '../assets/icomoon/icomoon';
import SliderContainer from '../components/SliderContainer';
import { setFilterBathOption, setFilterBedOption, setFilterMaxPrice, setFilterMinPrice, setFilterParkingOption } from '../redux/actions';
import { useAppDispatch, useAppSelector } from '../redux/store';
import type { TreedStackScreenProps } from '../types';

const BED = "bed";
const BATH = "bath";
const PARKING = "parking";

const Filter: React.FC<TreedStackScreenProps<"Filter">> = ({ navigation }) => {
  const { minPrice, maxPrice, bedOption, bathOption, parkingOption, filteredListings } = useAppSelector(state => state.listings);
  const [priceRange, setPriceRange] = useState([minPrice, maxPrice])
  const bedroomOptions = ['Studio', '1', '1 + 1', '2', '2 + 1', '3', '3 + 1', '4', '4 + 1', '5+']
  const bathroomOptions = ['1', '2', '3', '4', '5+']
  const parkingOptions = ['1', '2', '3', '4', '5+']

  const dispatch = useAppDispatch();

  const { Icon, fontsLoaded } = getIcoMoonFont();

  useEffect(() => {
    const [newMinPrice, newMaxPrice] = priceRange;
    if (minPrice !== newMinPrice) {
      dispatch(setFilterMinPrice(newMinPrice));
    }
    if (maxPrice !== newMaxPrice) {
      dispatch(setFilterMaxPrice(newMaxPrice));
    }
  }, [priceRange]);

  if (!fontsLoaded) {
    return (<View><Text>Loading...</Text></View>)
  };

  const displayPrice = (price: number) => {
    return (
      <NumberFormat
        value={price}
        thousandSeparator={true}
        prefix={'$'}
        decimalScale={0}
        displayType={'text'} />
    )
  };

  const createChoices = (value: string, type: string) => {
    const filterAction = () => {
      let option;
      if (type === BED) {
        option = value === bedOption ? undefined : value;
        dispatch(setFilterBedOption(option));
      } else if (type === BATH) {
        option = value === bathOption ? undefined : value;
        dispatch(setFilterBathOption(option));
      } else if (type === PARKING) {
        option = value === parkingOption ? undefined : value;
        dispatch(setFilterParkingOption(option));
      }
    };

    const isSelected = (type === BED && bedOption === value) || (type === BATH && bathOption === value) || (type === PARKING && parkingOption === value);

    return (
      <TouchableOpacity key={value} style={StyleSheet.flatten([styles.choiceButton, { borderColor: isSelected ? "#1c1c1e" : "#dadadd" }])} onPress={filterAction}>
        <Text style={StyleSheet.flatten([styles.choiceText, { color: isSelected ? "#1c1c1e" : "#97979a" }])}>{value}</Text>
      </TouchableOpacity>
    );
  };

  const reset = () => {
    setPriceRange([0, 2000000]);
    dispatch(setFilterBedOption(undefined));
    dispatch(setFilterBathOption(undefined));
    dispatch(setFilterParkingOption(undefined));
  };

  return (
    <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
      <View style={styles.header}>
        <Text style={{ fontSize: 18 }}>Filter</Text>
        <TouchableOpacity onPress={() => navigation.navigate('Treed')}>
          <Icon name='close' size={18} style={styles.close} />
        </TouchableOpacity>
      </View>
      <View style={styles.content}>
        <Text style={styles.title}>Price Range</Text>
        <Text style={styles.rangeText}>{displayPrice(priceRange[0])} to {displayPrice(priceRange[1])}</Text>
        <View style={{
          display: 'flex',
          justifyContent: 'center',
          paddingVertical: 24
        }}>
          <SliderContainer
            value={priceRange}
            setValue={setPriceRange}>
            <Slider
              animateTransitions
              maximumTrackTintColor="#d3d3d3"
              maximumValue={2000000}
              minimumTrackTintColor="#1fb28a"
              minimumValue={0}
              step={5000}
              thumbTintColor="#1a9274"
            />
          </SliderContainer>
        </View>
        <Text style={styles.title}>Beds</Text>
        <Text style={styles.optionContainer}>
          {
            bedroomOptions.map((option) => {
              return createChoices(option, BED)
            })
          }
        </Text>
        <Text style={styles.title}>Baths</Text>
        <Text style={styles.optionContainer}>
          {
            bathroomOptions.map((option) => {
              return createChoices(option, BATH)
            })
          }
        </Text>
        <Text style={styles.title}>Parking</Text>
        <Text style={styles.optionContainer}>
          {
            parkingOptions.map((option) => {
              return createChoices(option, PARKING)
            })
          }
        </Text>
      </View>
      <View style={styles.footer}>
        <TouchableOpacity onPress={reset}><Text>Clear All</Text></TouchableOpacity>
      </View>
    </ScrollView>
  );
}


const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F9F9F9',
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: 64,
    borderBottomWidth: 1,
    borderBottomColor: '#dadadd',
    paddingHorizontal: 20,
  },
  close: {
    color: '#1C1C1E',
  },
  content: {
    width: '100%',
    paddingHorizontal: 20,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: 73,
    borderTopWidth: 1,
    borderTopColor: '#dadadd',
    paddingHorizontal: 20,
  },
  button: {
    width: 86,
    height: 40,
    backgroundColor: '#378c79',
    borderRadius: 4,
    border: '1px solid #378c79',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 16,
  },
  buttonText: {
    fontSize: 16,
    fontWeight: '600',
    color: '#fff'
  },
  choiceButton: {
    backgroundColor: '#fff',
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 20,
    paddingHorizontal: 16,
    paddingVertical: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  choiceText: {
    fontSize: 15,
  },
  optionContainer: {
    gap: 10,
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: 8,
    paddingBottom: 24,
  },
  title: {
    fontSize: 17,
    fontWeight: '600',
    color: '#1c1c1e'
  },
  rangeText: {
    fontSize: 15,
    color: '#1c1c1e',
    paddingTop: 8,
  }
});

export default Filter;
