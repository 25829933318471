import React, { useState } from 'react';
import { Dimensions, Image, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import getIcoMoonFont from '../assets/icomoon/icomoon';

interface IAgent {
  id: number;
  name: string;
  phone: string;
  email: string;
};

interface IProject {
  name: string;
  address: string;
  image: string;
};

interface IListing {
  id: number;
  agent: IAgent;
  balcony: string;
  bathroom: number;
  bedroom: number;
  current_deposit: number;
  den: number;
  description: string;
  exposure: string;
  future_deposit: number;
  listing_price: number;
  locker: number;
  maintenance_fee: number;
  parking: number;
  project: IProject;
  purchase_price: number;
  size: string;
  unit: string;
};

interface ShareProp {
  goBack: () => void;
  listing: IListing;
  uri: string;
};


const { height } = Dimensions.get('window');

const Email: React.FC<ShareProp> = ({ goBack, listing, uri }) => {
  const [recipient, setRecipient] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const { Icon, fontsLoaded } = getIcoMoonFont();

  if (!fontsLoaded) {
    return (<View><Text>Loading...</Text></View>)
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={{ fontSize: 17 }}>Email</Text>
        <TouchableOpacity onPress={goBack}>
          <Icon name='close' size={15} color='#1c1c1e' />
        </TouchableOpacity>
      </View>
      <ScrollView style={{ padding: 20 }}>
        <View style={{ borderBottomColor: '#dadadd', borderBottomWidth: 1, paddingBottom: 20 }}>
          <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 16, gap: 12 }}>
            <View style={{ width: 77, height: 68, borderRadius: 2 }}>
              {
                listing.project.image ?
                  <Image
                    style={{
                      width: '100%',
                      height: '100%',
                      borderRadius: 2,
                    }}
                    source={{ uri: uri }} /> :
                  <View style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#b7b7b9',
                    borderRadius: 4,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    <Icon name='noImage' size={40} style={{ color: '#dadadd' }} />
                  </View>
              }
            </View>
            <View style={{ display: 'flex', gap: 16, justifyContent: 'center' }}>
              <Text style={{ color: '#1c1c1e', fontSize: 17, fontWeight: '600' }}>{listing.project.name}</Text>
              <Text style={{ color: '#97979a', fontSize: 15 }}>{listing.project.address}</Text>
            </View>
          </View>
        </View>
        <View style={{ marginTop: 24, display: 'flex', gap: 16 }}>
          <TextInput
            autoFocus={true}
            placeholder={'Recipient\'s email'}
            style={styles.input}
            onChangeText={setRecipient}
            value={recipient}
          />
          <TextInput
            autoFocus={true}
            placeholder={'Your email'}
            style={styles.input}
            onChangeText={setEmail}
            value={email}
          />
          <TextInput
            autoFocus={true}
            placeholder={'Message (Optional)'}
            style={StyleSheet.flatten([styles.input, { height: 200 }])}
            onChangeText={setMessage}
            value={message}
          />
        </View>
        <View style={{ width: '100%', display: 'flex', alignItems: 'flex-end', marginTop: 16 }}>
          <TouchableOpacity style={styles.button} onPress={() => { }}>
            <Text style={{ color: '#fff' }}>Send</Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </View>
  )
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F9F9F9',
    height: height,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: 64,
    borderBottomWidth: 1,
    borderBottomColor: '#dadadd',
    paddingHorizontal: 20,
  },
  input: {
    height: 60,
    width: '100%',
    borderWidth: 1,
    borderColor: '#dadadd',
    backgroundColor: '#fff',
    borderRadius: 4,
    alignItems: 'flex-start',
    paddingHorizontal: 16
  },
  button: {
    width: 84,
    height: 48,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#378c79',
  }
});

export default Email;
