import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import FilterButton from '../components/FilterButton';
import Header from '../components/Header';
import ProjectCards from '../components/ProjectCards';
import SearchBar from '../components/SearchBar';
import Signup from '../components/Signup';
import { useAppSelector } from '../redux/store';
import type { TreedStackScreenProps } from '../types';

const HomeScreen: React.FC<TreedStackScreenProps<'Treed'>> = ({ navigation }) => {
  const currentUser = useAppSelector(state => state.account.currentUser);

  const toMain = () => {
    navigation.navigate('Treed');
  }

  const openFilterModal = () => {
    navigation.navigate('Filter');
  };

  const goToSignup = () => {
    navigation.navigate('Signup');
  };

  const goToProject = (id: string) => {
    navigation.navigate('Project', { id });
  };

  const openSearchModal = () => {
    navigation.navigate('Search');
  };

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <Header toMain={toMain} />
        <SearchBar openSearchModal={openSearchModal} />
        <FilterButton goToFilter={openFilterModal} />
      </View>
      <ScrollView style={styles.scrollView}>
        {!currentUser && <Signup goToSignup={goToSignup} />}
        <ProjectCards goToProject={goToProject} />
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: '#f9f9f9',
    alignItems: 'center',
  },
  headerContainer: {
    width: '100%',
    // backgroundColor: '#f9f9f9',
    borderBottomColor: "#dadadd",
    borderBottomWidth: 1,
  },
  scrollView: {
    flex: 1,
    width: '100%',
    height: '100%',
    paddingHorizontal: 20,
    paddingVertical: 24
  },
  backdropStyle: {
    justifyContent: 'flex-end',
  },
  overlayStyle: {
    padding: 0,
  }
});

export default HomeScreen;
