import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AccountState {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  license: string;
  brokerage: string;
  phone: string;
  status: number;
  currentUser: string | undefined;
  currentToken: string;
};

const initialState = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  license: '',
  brokerage: '',
  phone: '',
} as AccountState

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccountEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload
    },
    setAccountPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload
    },
    setAccountFirstName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload
    },
    setAccountLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload
    },
    setAccountLicense: (state, action: PayloadAction<string>) => {
      state.license = action.payload
    },
    setAccountBrokerage: (state, action: PayloadAction<string>) => {
      state.brokerage = action.payload
    },
    setAccountPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload
    },
    setCurrentUser: (state, action: PayloadAction<string | undefined>) => {
      state.currentUser = action.payload
    },
    setCurrentToken: (state, action: PayloadAction<string>) => {
      state.currentToken = action.payload
    },
  },
});

export const { setAccountEmail, setAccountPassword, setAccountFirstName, setAccountLastName, setAccountLicense, setAccountBrokerage, setAccountPhone, setCurrentUser, setCurrentToken } = accountSlice.actions;
export default accountSlice.reducer;