import axios from 'axios';
// @ts-ignore
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { Platform, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { setCurrentUser } from '../redux/actions';
import { useAppDispatch } from "../redux/store";
import { RefreshToken } from '../util';

interface LoginProps {
  toSignup: () => void;
  toMain: () => void;
}

const Login: React.FC<LoginProps> = ({ toSignup, toMain }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [err, setErr] = useState('')
  const dispatch = useAppDispatch();

  const login = async () => {
    try {
      const tokens = await axios.post('api/token/', { email, password });

      const { refresh, access } = tokens.data;

      Cookies.set("treedRefreshToken", refresh, { secure: true });
      Cookies.set("treedTimestamp", Date.now(), { secure: true });
      Cookies.set("treedUser", email, { secure: true });

      dispatch(setCurrentUser(email));

      // @ts-ignore
      axios.defaults.headers['Authorization'] = `Bearer ${access}`;

      RefreshToken();

      toMain();
    } catch (e) {
      // console.log(e);
      setErr("Something went wrong");
    }
  };

  return (
    <View>
      <Text style={textStyles.title}>Login</Text>
      <TextInput
        autoFocus={true}
        placeholder={'Email address'}
        placeholderTextColor="#97979a"
        style={
          StyleSheet.flatten([
            styles.input,
            { borderColor: err ? '#b82e14' : '#dadadd' },
            Platform.OS == 'web' && { outlineStyle: 'none' }
          ])
        }
        onChangeText={setEmail}
        value={email}
      />
      <TextInput
        placeholder={'Password'}
        placeholderTextColor="#97979a"
        secureTextEntry={true}
        style={
          StyleSheet.flatten([
            styles.input,
            { borderColor: err ? '#b82e14' : '#dadadd' },
            Platform.OS == 'web' && { outlineStyle: 'none' }
          ])
        }
        onChangeText={setPassword}
        value={password}
      />
      {err !== '' && <Text style={textStyles.error}>{err}</Text>}
      <View style={styles.login}>
        <Text style={textStyles.label}>Don't have an account?</Text>
        <TouchableOpacity onPress={toSignup}><Text style={{ fontWeight: '600' }}>Sign up</Text></TouchableOpacity>
      </View>
      <TouchableOpacity onPress={login} style={styles.button}>
        <Text style={textStyles.button}>Next</Text>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  input: {
    width: '100%',
    height: 60,
    paddingHorizontal: 16,
    backgroundColor: '#fff',
    borderRadius: 4,
    border: '1px solid #dadadd',
    marginVertical: 8
  },
  login: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    marginTop: 40
  },
  button: {
    width: 100,
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-end',
    backgroundColor: '#378c79',
    borderRadius: 4,
    marginTop: 32,
  }
});

const textStyles = StyleSheet.create({
  title: {
    fontSize: 21,
    fontWeight: '600',
    color: '#1c1c1e',
    marginBottom: 24
  },
  input: {
    fontSize: 17,
    color: '#97979a',
  },
  button: {
    fontSize: 16,
    fontWeight: '600',
    color: '#fff'
  },
  label: {
    fontSize: 14,
    color: '#1c1c1e'
  },
  error: {
    fontSize: 15,
    color: '#b82e14',
    paddingTop: 8
  }
});

export default Login;
