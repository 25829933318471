import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import getIcoMoonFont from '../assets/icomoon/icomoon';
import { useAppDispatch, useAppSelector } from '../redux/store';
import type { TreedStackScreenProps } from '../types';

interface IProject {
  id: string;
  name: string;
  numListings: number;
}

const SearchModalScreen: React.FC<TreedStackScreenProps<'Search'>> = ({ navigation }) => {
  const [projects, setProjects] = useState([]);
  const searchText = useAppSelector(state => state.treed.searchText);
  const [text, setText] = useState(searchText);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const getProjects = async () => {
      try {
        const response = await axios.get(`${axios.defaults.baseURL}/api/projects/search_results/`);
        setProjects(response.data);
      } catch (err) {
        console.log("Error", err);
      }
    };
    getProjects();
  }, []);

  // useEffect(() => {
  //   dispatch(setSearchText(text));
  // }, [text]);

  const { Icon, fontsLoaded } = getIcoMoonFont();

  if (!fontsLoaded || !projects) {
    return (<View><Text>Loading...</Text></View>)
  }

  const renderProjects = (project: IProject) => {
    return (
      <TouchableOpacity key={project.id} style={styles.container}>
        <Icon name='buildings' size={23} style={styles.icon} />
        <View>
          <Text style={textStyles.text}>{project.name}</Text>
          <Text style={textStyles.label}>{project.numListings} listing(s)</Text>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <View style={overlayStyles.container}>
      <View style={overlayStyles.optionHeader}>
        <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', }} onPress={() => { navigation.goBack() }}>
          <Icon name='arrowLeft' size={24} color='#6f6f71' style={overlayStyles.arrowIcon} />
        </TouchableOpacity>
        <TextInput
          autoFocus={true}
          style={overlayStyles.input}
          onChangeText={setText}
          value={text}
        />
      </View>
      <ScrollView style={overlayStyles.content}>
        {/* <Text style={overlayStyles.heading}>SELECT A REGION</Text>
        {
          regionList.map((region) => {
            return (
              <View key={region} style={optionStyles.container}>
                <Icon name='pin' size={23} style={optionStyles.icon} />
                <Text style={optionStyles.text}>{region}</Text>
              </View>
            )
          })
        } */}
        <Text style={overlayStyles.heading}>SELECT A PROJECT</Text>
        {<View>{projects.map(project => renderProjects(project))}</View>}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
    gap: 12
  },
  icon: {
    height: 44,
    width: 44,
    lineHeight: 44,
    textAlign: 'center',
    borderRadius: 22,
    backgroundColor: '#b7b7b9',
    color: '#1c1c1e'
  },
});

const textStyles = StyleSheet.create({
  text: {
    fontSize: 15,
    color: '#1c1c1e',
    paddingBottom: 4,
  },
  icon: {

  },
  label: {
    fontSize: 13,
    color: '#97979a',
  },
});


const overlayStyles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#f9f9f9'
  },
  content: {
    paddingHorizontal: 20,
    paddingVertical: 4
  },
  arrowIcon: {
    position: 'absolute',
    left: 16,
  },
  optionHeader: {
    height: 56,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderBottomColor: '#dadadd',
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    flex: 1,
    height: 56,
    paddingHorizontal: 40,
  },
  heading: {
    fontSize: 13,
    color: '#1c1c1e',
    fontWeight: '600',
    paddingVertical: 16
  }
});

const optionStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
    gap: 16
  },
  icon: {
    height: 48,
    width: 48,
    lineHeight: 48,
    textAlign: 'center',
    borderRadius: 24,
    backgroundColor: '#dadadd',
    color: '#1c1c1e'
  },
  text: {
    fontSize: 17,
    color: '#1c1c1e'
  }
});

export default SearchModalScreen;
