import { createSlice } from '@reduxjs/toolkit';
import getIcoMoonFont from '../../assets/icomoon/icomoon';

interface IcoMoonState {
    Icon: any,
    fontsLoaded: boolean
};

const initialState = { Icon: null, fontsLoaded: false } as IcoMoonState

const icomoonSlice = createSlice({
    name: 'icomoon',
    initialState,
    reducers: {
        setIcon: (state) => {
            const { Icon, fontsLoaded } = getIcoMoonFont();
            state.Icon = Icon;
            state.fontsLoaded = fontsLoaded;
        },
    },
});

export const { setIcon } = icomoonSlice.actions;
export default icomoonSlice.reducer;
