import { Overlay } from "@rneui/themed";
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import getIcoMoonFont from "../assets/icomoon/icomoon";
import ChangeEmail from "../components/ChangeEmail";
import ChangePassword from "../components/ChangePassword";
import Header from '../components/Header';
import { setAccountBrokerage, setAccountFirstName, setAccountLastName, setAccountLicense, setAccountPhone } from '../redux/actions';
import { useAppDispatch, useAppSelector } from '../redux/store';
import type { TreedStackScreenProps } from '../types';
import { RefreshToken } from '../util';

const ProfileScreen: React.FC<TreedStackScreenProps<'Treed'>> = ({ navigation }) => {
  const [expandEmail, setExpandEmail] = useState(false);
  const [expandPassword, setExpandPassword] = useState(false);
  const [errorFirstName, setErrorFirstName] = useState('');
  const [errorLastName, setErrorLastName] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorBrokerage, setErrorBrokerage] = useState('');
  const [errorPhone, setErrorPhone] = useState('');
  const [errorLicense, setErrorLicense] = useState('');
  const { Icon, fontsLoaded } = getIcoMoonFont();
  const account = useAppSelector(state => state.account);
  const dispatch = useAppDispatch();

  const handleChooseFile = (acceptedFiles: any) => {
    console.log(acceptedFiles);
  };

  useEffect(() => {
    const getUser = async () => {
      await RefreshToken();
      const url = `${axios.defaults.baseURL}/api/users/current/`;
      const resp = await axios.get(url);
      dispatch(setAccountFirstName(resp.data.first_name));
      dispatch(setAccountLastName(resp.data.last_name));
      dispatch(setAccountLicense(resp.data.license));
      dispatch(setAccountBrokerage(resp.data.brokerage));
      dispatch(setAccountPhone(resp.data.phone));
    }
    getUser();
  }, []);
  if (!fontsLoaded) {
    return (<View><Text>Loading...</Text></View>)
  }

  const toMain = () => {
    navigation.navigate('Treed');
  }

  const toggleEmailEdit = () => {
    setExpandEmail(!expandEmail);
  }

  const togglePasswordEdit = () => {
    setExpandPassword(!expandPassword);
  }

  const renderLicense = () => {
    if (!account.license) {
      return undefined;
    }
    return (
      <>
        <TextInput
          placeholder={'License Number'}
          placeholderTextColor="#97979a"
          style={styles.input}
          onChangeText={(e) => dispatch(setAccountLicense(e))}
          value={account.license}
        />
        {
          account.license === '' && errorLicense !== '' &&
          <Text style={textStyles.error}>{errorLicense}</Text>
        }
      </>
    );
  }
  const renderBrokerage = () => {
    if (!account.brokerage) {
      return undefined;
    }
    return (
      <>
        <TextInput
          placeholder={'Brokerage Name'}
          placeholderTextColor="#97979a"
          style={styles.input}
          onChangeText={(e) => dispatch(setAccountBrokerage(e))}
          value={account.brokerage}
        />
        {
          account.brokerage === '' && errorBrokerage !== '' &&
          <Text style={textStyles.error}>{errorLicense}</Text>
        }
      </>
    );
  }
  const renderContact = () => {
    if (!account.phone) {
      return undefined;
    }
    return (
      <>
        <TextInput
          placeholder={'Contact Number'}
          placeholderTextColor="#97979a"
          style={styles.input}
          onChangeText={(e) => dispatch(setAccountPhone(e))}
          value={account.phone}
        />
        {
          account.phone === '' && errorPhone !== '' &&
          <Text style={textStyles.error}>{errorLicense}</Text>
        }
      </>
    );
  }


  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <Header toMain={toMain} />
      </View>
      <ScrollView style={styles.scrollView}>
        {/* Profile */}
        <View style={{ marginBottom: 56 }}>
          <Text style={textStyles.title}>Profile</Text>
          <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginVertical: 20 }}>
            <View style={{ width: 96, height: 96, borderRadius: 48, backgroundColor: '#d9d9d9', marginRight: 16 }}></View>
            <TouchableOpacity>
              <View style={{ width: 129, height: 36, display: 'flex', justifyContent: 'center', alignItems: 'center', borderColor: '#b7b7b9', borderRadius: 4, borderWidth: 1 }}>
                <Text style={{ fontSize: 15, fontWeight: '600', color: '#1c1c1e' }}>Update photo</Text>
                {/* <input type="file" onChange={fileSelectedHandler} accept="image/*" /> */}
              </View>
            </TouchableOpacity>

          </View>
          <TextInput
            autoFocus={true}
            placeholder={'First name'}
            placeholderTextColor="#97979a"
            style={styles.input}
            onChangeText={(e) => dispatch(setAccountFirstName(e))}
            value={account.firstName}
          />
          {
            account.firstName === '' && errorFirstName !== '' &&
            <Text style={textStyles.error}>{errorFirstName}</Text>
          }
          <TextInput
            placeholder={'Last name'}
            placeholderTextColor="#97979a"
            style={styles.input}
            onChangeText={(e) => dispatch(setAccountLastName(e))}
            value={account.lastName}
          />
          {
            account.lastName === '' && errorLastName !== '' &&
            <Text style={textStyles.error}>{errorLastName}</Text>
          }
          {renderLicense()}
          {renderBrokerage()}
          {renderContact()}
          <TouchableOpacity>
            <View>
              <View style={StyleSheet.flatten([styles.button, { width: 140 }])}>
                <Text style={textStyles.button}>Save Changes</Text>
              </View>
            </View>
          </TouchableOpacity>
        </View>
        {/* Account */}
        <View>
          <Text style={textStyles.title}>Account Settings</Text>
          <View style={StyleSheet.flatten([styles.input, { display: 'flex', justifyContent: 'center', marginTop: 28 }])}>
            <Text style={textStyles.input}>{account.email}</Text>
          </View>
          <TouchableOpacity onPress={toggleEmailEdit}>
            <View style={StyleSheet.flatten([styles.button, { width: 135 }])}>
              <Text style={textStyles.button}>Change email</Text>
            </View>
          </TouchableOpacity>
          <View style={StyleSheet.flatten([styles.input, { display: 'flex', justifyContent: 'center', marginTop: 28 }])}>
            <Text style={textStyles.input}>**********</Text>
          </View>
          <TouchableOpacity onPress={togglePasswordEdit}>
            <View style={StyleSheet.flatten([styles.button, { width: 168 }])}>
              <Text style={textStyles.button}>Change password</Text>
            </View>
          </TouchableOpacity>
        </View>
      </ScrollView>

      {/* Chagne Email */}

      <Overlay isVisible={expandEmail} onBackdropPress={toggleEmailEdit} fullScreen={true} overlayStyle={{ backgroundColor: '#f9f9f9', padding: 0 }} animationType={'slide'}>
        <ChangeEmail toggleEmailEdit={toggleEmailEdit} />
      </Overlay>
      {/* Change password */}

      <Overlay isVisible={expandPassword} onBackdropPress={togglePasswordEdit} fullScreen={true} overlayStyle={{ backgroundColor: '#f9f9f9', padding: 0 }} animationType={'slide'}>
        <ChangePassword togglePasswordEdit={togglePasswordEdit} />
      </Overlay>
    </View >
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: '#f9f9f9',
    alignItems: 'center',
  },
  headerContainer: {
    width: '100%',
    backgroundColor: '#f9f9f9',
    height: 64,
    borderBottomColor: "#dadadd",
    borderBottomWidth: 1,
  },
  scrollView: {
    flex: 1,
    width: '100%',
    height: '100%',
    paddingHorizontal: 20,
    paddingVertical: 24
  },
  input: {
    width: '100%',
    height: 60,
    paddingHorizontal: 16,
    backgroundColor: '#fff',
    borderRadius: 4,
    border: '1px solid #dadadd',
    marginVertical: 8,
    fontSize: 17,
    color: '#1c1c1e',
  },
  button: {
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    paddingHorizontal: 16,
    backgroundColor: '#378c79',
    borderRadius: 4,
    marginTop: 16
  }
});

const textStyles = StyleSheet.create({
  title: {
    fontSize: 21,
    fontWeight: '600',
    color: '#1c1c1e'
  },
  subTitle: {
    fontSize: 15,
    color: '#97979a',
    marginTop: 12,
    marginBottom: 24
  },
  input: {
    fontSize: 17,
    color: '#97979a',
  },
  button: {
    fontSize: 16,
    fontWeight: '600',
    color: '#fff'
  },
  label: {
    fontSize: 14,
    color: '#1c1c1e'
  },
  error: {
    fontSize: 15,
    color: '#b82e14',
    paddingBottom: 8
  }
});

export default ProfileScreen;
