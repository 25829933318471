// import { Link } from '@react-navigation/native';
import React, { useEffect, useState } from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import NumberFormat from 'react-number-format';
import getIcoMoonFont from '../assets/icomoon/icomoon';
import { fetchListings, fetchProjects } from "../redux/actions";
import { useAppDispatch, useAppSelector } from "../redux/store";

interface Project {
  id: number;
  address: string;
  builders: string;
  city: string;
  lat: number;
  lng: number;
  name: string;
  postal_code: string;
  province: string;
  type: string;
  listingCount: number;
  minPrice: number;
  maxPrice: number;
  image: string;
}

interface ProjectCardProps {
  goToProject: (id: string) => void;
};

const ProjectCards: React.FC<ProjectCardProps> = ({ goToProject }) => {
  const [projectIDs, setProjectIDs] = useState<number[]>([]);
  const [targetProjects, setTargetProjects] = useState<Project[]>([]);
  const projects = useAppSelector(state => state.projects.projects);
  const { listings, filteredListings } = useAppSelector(state => state.listings);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchListings());
    dispatch(fetchProjects());
  }, []);

  useEffect(() => {
    if (listings?.length !== filteredListings?.length) {
      setProjectIDs([...new Set(filteredListings.map((l) => l.project.id))]);
    } else {
      setProjectIDs([]);
    }
  }, [filteredListings]);

  useEffect(() => {
    setTargetProjects(projects);
    if (projectIDs.length > 0) {
      setTargetProjects(projects.filter((project) => projectIDs.includes(project.id)));
    }
  }, [projects, projectIDs]);

  const { Icon, fontsLoaded } = getIcoMoonFont();

  if (!fontsLoaded) {
    return (<View><Text>Loading...</Text></View>)
  }

  const priceRange = (minPrice: number, maxPrice: number) => {
    return (
      <Text style={styles.range}>
        <Text>From </Text>
        <NumberFormat
          value={minPrice}
          thousandSeparator={true}
          prefix={'$'}
          decimalScale={0}
          displayType={'text'} />
        <Text> to </Text>
        <NumberFormat
          value={maxPrice}
          thousandSeparator={true}
          prefix={'$'}
          decimalScale={0}
          displayType={'text'} />
      </Text>
    )
  };

  const createCard = (project: Project) => {
    return (
      <View style={styles.card} key={project.id}>
        <View style={styles.picture}>
          {
            project.image ?
              <Image
                style={{
                  width: '100%',
                  height: '100%',
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                }}
                source={{ uri: `https://www.treedrealestate.com${project.image}` }} /> :
              <View style={{
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <Icon name='noImage' size={64} style={{ color: '#dadadd', paddingBottom: 8 }} />
                <Text style={{ fontSize: 24, color: '#b7b7b9' }}>Coming Soon</Text>
              </View>
          }
        </View>
        <View style={styles.textBox}>
          <Text style={styles.name}>{project.name}</Text>
          <Text style={styles.address}>{project.address}, {project.city}, {project.province}</Text>
          {priceRange(project.minPrice, project.maxPrice)}
          <TouchableOpacity style={styles.button} onPress={() => { goToProject((project.id).toString()) }}>
            {/* <Link to={`/project/`}> */}
            <Text style={styles.buttonText}>{`See ${project.listingCount} listings`}</Text>
            {/* </Link> */}
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <Text style={styles.count}>{targetProjects ? targetProjects.length : 0} projects</Text>
      {
        projects ?
          projects.map(project => {
            return createCard(project);
          }) :
          null
      }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  count: {
    fontSize: 13,
    marginBottom: 8,
    color: '#1c1c1e',
  },
  card: {
    width: '100%',
    height: 360,
    backgroundColor: '#fff',
    borderRadius: 8,
    marginTop: 16,
    shadowColor: '#171717',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 1,
  },
  picture: {
    width: '100%',
    height: 180,
    backgroundColor: '#f2f2f5',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  textBox: {
    width: '100%',
  },
  name: {
    fontSize: 17,
    fontWeight: '600',
    color: '#1c1c1e',
    margin: 16,
    marginBottom: 8,
  },
  address: {
    fontSize: 15,
    color: '#6f6f71',
    marginLeft: 16,
  },
  range: {
    fontSize: 15,
    color: '#1c1c1e',
    marginTop: 12,
    marginLeft: 16,
  },
  button: {
    width: 143,
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 30,
    border: '1px solid #dadadd',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    margin: 24,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: '600',
    color: '#378c79'
  }
})

export default ProjectCards;