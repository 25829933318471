import axios from 'axios';
import React, { useEffect, useState } from "react";
import { Dimensions, Image, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import NumberFormat from 'react-number-format';
import getIcoMoonFont from '../assets/icomoon/icomoon';
import { useAppSelector } from '../redux/store';
import type { TreedStackScreenProps } from '../types';

interface IImages {
  image: IImage;
}

interface IImage {
  full_size: string;
  thumbnail: string;
}

interface IProject {
  id: number;
  address: string;
  builders: string;
  city: string;
  lat: number;
  lng: number;
  name: string;
  postal_code: string;
  province: string;
  type: string;
  listingCount: number;
  minPrice: number;
  maxPrice: number;
  completion: string;
}

interface IListing {
  id: number;
  agent: IAgent;
  bathroom: number;
  bedroom: number;
  den: number;
  listing_price: number;
  parking: number;
  unit: string;
}

interface IAgent {
  id: number;
  name: string;
  brokerage: string;
}

const { height } = Dimensions.get('window');

const ProjectScreen: React.FC<TreedStackScreenProps<'Project'>> = ({ navigation, route }) => {
  const [images, updateImages] = useState<IImages[] | null>(null);
  const [detail, updateDetail] = useState<IProject | null>(null);
  const [listings, updateListings] = useState<IListing[] | null>(null);
  const {
    minPrice,
    maxPrice,
    bedroom,
    den,
    bathroom,
    parking
  } = useAppSelector(state => state.listings)
  const projectID = parseInt(route.params.id, 10);

  useEffect(() => {
    const getProject = async (projectID: number) => {
      const resp = await axios.get(`${axios.defaults.baseURL}/api/projects/${projectID}/details/`);
      const data = resp.data;
      updateImages(data.images);
      updateDetail(data.project);
      updateListings(data.listings);
    };
    if (projectID > 0) {
      getProject(projectID);
    }
  }, [projectID]);

  const { Icon, fontsLoaded } = getIcoMoonFont();

  const filterListingCards = () => {
    const candidate = (l: IListing) => {
      const minPriceCondition = l.listing_price > minPrice;
      const maxnPriceCondition = l.listing_price <= maxPrice;
      const bedroomCondition = bedroom
        ? l.bedroom === bedroom
        : true;
      const bathroomCondition = bathroom
        ? l.bathroom === bathroom
        : true;
      const denCondition = den ? l.den > 1 : true;
      const parkingCondition = parking ? l.parking === parking : true;
      return minPriceCondition && maxnPriceCondition && bedroomCondition && bathroomCondition && denCondition && parkingCondition;
    };
    let filtered: IListing[] = [];
    let other: IListing[] = [];
    listings?.forEach((listing, i) => {
      if (candidate(listing)) {
        filtered.push(listing);
      } else {
        other.push(listing);
      }
    });
    return { filtered, other }
  };

  const createListingCard = (listing: IListing, green: boolean = false) => {
    return (
      <TouchableOpacity key={listing.id} style={{ marginBottom: 16 }} onPress={() => { navigation.navigate('Listing', { id: listing.id.toString() }) }}>
        <View style={StyleSheet.flatten([styles.listingContainer, { borderColor: green ? '#378c79' : '#dadadd' }])}>
          {/* Agent */}
          <View style={styles.row}>
            <View>
              <View style={{ width: 40, height: 40, borderRadius: 20, backgroundColor: '#1c1c1e', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={StyleSheet.flatten([textStyles.medium, textStyles.white])}>{listing.agent.name.at(0)}</Text>
              </View>
            </View>
            <View style={{ display: 'flex', width: '75%' }}>
              <Text style={StyleSheet.flatten([textStyles.small, textStyles.dark, textStyles.bold, { marginBottom: 4 }])}>{listing.agent.name}</Text>
              <Text style={StyleSheet.flatten([textStyles.small, textStyles.light])}>{listing.agent.brokerage}</Text>
            </View>
          </View>
          {/* Listing detail */}
          <View style={{ marginTop: 12 }}>
            <Text style={StyleSheet.flatten([textStyles.small, textStyles.dark])}>Unit {listing.unit}</Text>
            <View style={{ marginVertical: 4 }}>
              <Text style={StyleSheet.flatten([textStyles.large, textStyles.dark, textStyles.bold])}>
                <NumberFormat
                  value={listing.listing_price}
                  thousandSeparator={true}
                  prefix={'$'}
                  decimalScale={0}
                  displayType={'text'} />
              </Text>
            </View>
            <View style={styles.listingDetail}>
              <View style={styles.row}>
                <Icon name='bed' size={21} color='#97979a' />
                <Text>{listing.bedroom}</Text>
              </View>
              <View style={styles.row}>
                <Icon name='bath' size={21} color='#97979a' />
                <Text>{listing.bathroom}</Text>
              </View>
              <View style={styles.row}>
                <Icon name='park' size={21} color='#97979a' />
                <Text>{listing.parking}</Text>
              </View>
            </View>
          </View>
        </View >
      </TouchableOpacity >
    );
  };

  const renderListingCards = () => {
    const { filtered, other } = filterListingCards();

    if (filtered.length === 0 || other.length === 0) {
      return (
        <View style={styles.column}>
          <Text style={StyleSheet.flatten([textStyles.medium, textStyles.dark, textStyles.bold, { marginBottom: 16 }])}>Listings</Text>
          {
            listings?.map((listing) =>
              createListingCard(listing))
          }
        </View>
      )
    } else {
      return (
        <>
          <View style={styles.column}>
            <Text style={StyleSheet.flatten([textStyles.medium, textStyles.dark, textStyles.bold, { marginBottom: 16 }])}>Filtered</Text>
            {
              filtered.map((listing) =>
                createListingCard(listing, true))
            }
          </View>
          <View style={styles.column}>
            <Text style={StyleSheet.flatten([textStyles.medium, textStyles.dark, textStyles.bold, { marginBottom: 16 }])}>Other listings</Text>
            {
              other.map((listing) =>
                createListingCard(listing))
            }
          </View>
        </>
      );
    }
  };

  if (!fontsLoaded || projectID < 0 || !images || !detail || !listings) {
    return (<View><Text>Loading...</Text></View>)
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity style={{ zIndex: 2 }} onPress={() => navigation.navigate('Treed')}>
        <Icon name='arrowLeftSmall' size={21} color='#6f6f71' style={styles.back} />
      </TouchableOpacity>
      <ScrollView>
        <View style={{ height: 250, justifyContent: 'center', alignItems: 'center' }}>
          {
            images.length > 0 ?
              <Image
                style={styles.gallery}
                source={{ uri: `${axios.defaults.baseURL}${images[0].image.full_size}` }} /> :
              <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Icon name='noImage' size={64} style={{ color: '#dadadd', paddingBottom: 8 }} />
                <Text style={{ fontSize: 24, color: '#b7b7b9' }}>Coming Soon</Text>
              </View>
          }
        </View>
        <View style={{ paddingHorizontal: 20 }}>
          {/* Project detail */}
          <View style={styles.column}>
            <Text style={StyleSheet.flatten([textStyles.xlarge, textStyles.dark, textStyles.bold])}>{detail.name}</Text>
            <Text style={StyleSheet.flatten([textStyles.large, textStyles.dark, textStyles.bold, { marginTop: 12, marginBottom: 4 }])}>
              <Text>From </Text>
              <NumberFormat
                value={detail.minPrice}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={0}
                displayType={'text'} />
              <Text> to </Text>
              <NumberFormat
                value={detail.maxPrice}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={0}
                displayType={'text'} />
            </Text>
            <View style={StyleSheet.flatten([styles.row, { marginTop: 4, alignItems: 'center' }])}>
              <Icon name='pin' size={15} color='#1c1c1e' />
              <Text style={StyleSheet.flatten([textStyles.medium, textStyles.dark])}>{detail.address}, {detail.city}, {detail.province}</Text>
            </View>
          </View>
          {/* Builder / Completion */}
          <View style={styles.column}>
            <View style={StyleSheet.flatten([styles.row, { marginBottom: 16 }])}>
              <Icon name="builder" size={24} color='#1c1c1e' style={{ marginRight: 16 }} />
              <View>
                <Text style={StyleSheet.flatten([textStyles.medium, textStyles.light, textStyles.bold])}>Builder(s)</Text>
                <Text style={StyleSheet.flatten([textStyles.medium, textStyles.dark, textStyles.bold])}>{detail.builders}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <Icon name="calendar" size={24} color='#1c1c1e' style={{ marginRight: 16 }} />
              <View>
                <Text style={StyleSheet.flatten([textStyles.medium, textStyles.light, textStyles.bold])}>Estimated Completion</Text>
                <Text style={StyleSheet.flatten([textStyles.medium, textStyles.dark, textStyles.bold])}>{detail.completion}</Text>
              </View>
            </View>
          </View>
          {/* Listings */}
          {renderListingCards()}
        </View>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#f9f9f9',
    height,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8
  },
  back: {
    position: 'absolute',
    top: 20,
    left: 20,
    fontSize: 32,
    backgroundColor: '#FEFEFE',
    zIndex: 2,
    border: "1px solid #dadadd",
    borderRadius: 4,
  },
  gallery: {
    width: '100%',
    height: 250,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f5',
  },
  column: {
    width: '100%',
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f5',
  },
  listingContainer: {
    width: '100%',
    backgroundColor: '#FFF',
    padding: 16,
    borderWidth: 1,
    borderRadius: 4,
    shadowColor: '#171717',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 1,
  },
  agent: {
    fontSize: 13,
    fontWeight: '600',
    color: '#1C1C1E',
  },
  brokerage: {
    fontSize: 13,
    color: '#97979a',
  },
  unit: {
    fontSize: 13,
    color: '#1C1C1E',
    paddingBottom: 8,
  },
  listingDetail: {
    display: 'flex',
    flexDirection: 'row',
    gap: 56
  },
});

const textStyles = StyleSheet.create({
  xlarge: { fontSize: 24 },
  large: { fontSize: 17 },
  medium: { fontSize: 15 },
  small: { fontSize: 13 },
  dark: { color: '#1c1c1e' },
  light: { color: '#97979a' },
  white: { color: '#fff' },
  bold: { fontWeight: '600' }
});

export default ProjectScreen;
