import { Overlay } from "@rneui/themed";
import axios from 'axios';
import React, { useEffect, useState } from "react";
import { Dimensions, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import NumberFormat from 'react-number-format';
import getIcoMoonFont from '../assets/icomoon/icomoon';
import type { TreedStackScreenProps } from '../types';

interface IAgent {
  id: number;
  name: string;
  phone: string;
}

interface IProject {
  id: string;
  name: string;
}

interface IListing {
  id: number;
  agent: IAgent;
  balcony: string;
  bathroom: number;
  bedroom: number;
  current_deposit: number;
  den: number;
  description: string;
  exposure: string;
  future_deposit: number;
  listing_price: number;
  locker: number;
  maintenance_fee: number;
  parking: number;
  project: IProject;
  purchase_price: number;
  size: string;
  unit: string;
}

const { height } = Dimensions.get('window');

const ListingScreen: React.FC<TreedStackScreenProps<'Listing'>> = ({ navigation, route }) => {
  const [expandDescription, setExpandDescription] = useState<boolean>(false);
  const [listing, updateListing] = useState<IListing | null>(null);
  const listingID = parseInt(route.params.id, 10);

  useEffect(() => {
    const getListing = async (listingID: number) => {
      const resp = await axios.get(`${axios.defaults.baseURL}/api/listings/${listingID}/`);
      const data = resp.data;
      console.log(data);
      updateListing(data);
    };
    if (listingID > 0) {
      getListing(listingID);
    }
  }, [listingID]);

  const { Icon, fontsLoaded } = getIcoMoonFont();

  if (!fontsLoaded || listingID < 0 || !listing) {
    return (<View><Text>Loading...</Text></View>)
  };

  const toggleOverlay = () => {
    setExpandDescription(!expandDescription);
  };

  return (
    <View style={styles.container}>
      <ScrollView>
        <View style={styles.header}>
          <TouchableOpacity style={styles.back} onPress={() => navigation.navigate('Project', { id: listing.project.id })}>
            <Icon name='arrowLeft' size={17} color='#6f6f71' />
          </TouchableOpacity>
          <Text style={{ fontSize: 15, color: "#1c1c1e" }}>{listing.project.name}</Text>
        </View>
        <View style={StyleSheet.flatten([styles.padding, { paddingBottom: 44, backgroundColor: '#f9f9f9' }])}>
          {/* Start of Body */}
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Text style={{ fontSize: 24, color: "#1c1c1e", fontWeight: '600' }}>
              Unit. {listing.unit}
            </Text>
            <TouchableOpacity onPress={() => navigation.navigate('Share', { id: route.params.id })}>
              <Icon name='share' size={21} color='#1c1c1e' />
            </TouchableOpacity>
          </View>
          <View style={styles.priceCard}>
            <Text style={{ fontSize: 21, color: '#fff', fontWeight: '600' }}>
              <NumberFormat
                value={listing.listing_price}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={0}
                displayType={'text'} />
            </Text>
            <View style={{ display: 'flex', flexDirection: 'row', gap: 40 }}>
              <Text style={{ fontSize: 13, color: '#fff' }}>
                <Icon name='bed' size={21} color='#fff' style={styles.icon} />
                {listing.bedroom}
              </Text>
              <Text style={{ fontSize: 13, color: '#fff' }}>
                <Icon name='bath' size={21} color='#fff' style={styles.icon} />
                {listing.bathroom}
              </Text>
              <Text style={{ fontSize: 13, color: '#fff' }}>
                <Icon name='park' size={21} color='#fff' style={styles.icon} />
                {listing.parking}
              </Text>
            </View>
          </View>
          {
            listing.description &&
            <View style={{ marginBottom: 40 }}>
              <Text style={textStyles.title}>About unit</Text>
              <View style={{ marginTop: 12 }}>
                {
                  listing.description.length <= 300 ?
                    <Text style={{ fontSize: 15, color: "#1c1c1e", lineHeight: 24 }}>{listing.description}</Text> :
                    <View>
                      <Text style={{ fontSize: 15, color: "#1c1c1e", lineHeight: 24 }}>{listing.description.substring(0, 300)}...</Text>
                      <TouchableOpacity onPress={toggleOverlay}>
                        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 12 }}>
                          <Text style={{ fontSize: 15, fontWeight: '600', color: '#1c1c1e', lineHeight: 13, marginRight: 8 }}>Show more</Text>
                          <Icon name="arrowRight" />
                        </View>
                      </TouchableOpacity>
                    </View>
                }
              </View>
            </View>
          }
          {/* Detail */}
          <View style={{ marginBottom: 40 }}>
            <Text style={textStyles.title}>Details</Text>
            <View style={styles.valueContainer}>
              {/* Maint Fee */}
              <View style={styles.detail}>
                <Text style={textStyles.label}>Maint Fee</Text>
                <Text style={textStyles.value}>
                  {
                    listing.maintenance_fee ?
                      <NumberFormat
                        value={listing.maintenance_fee}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={0}
                        displayType={'text'} /> :
                      '-'
                  }
                </Text>
              </View>
              {/* SQFT */}
              <View style={styles.detail}>
                <Text style={textStyles.label}>SQFT</Text>
                <Text style={textStyles.value}>
                  {
                    listing.size ? listing.size : '-'
                  }
                </Text>
              </View>
              {/* Locker */}
              <View style={styles.detail}>
                <Text style={textStyles.label}>Locker</Text>
                <Text style={textStyles.value}>
                  {
                    listing.locker ? listing.locker : '-'
                  }
                </Text>
              </View>
              {/* Balcony */}
              <View style={styles.detail}>
                <Text style={textStyles.label}>Balcony</Text>
                <Text style={textStyles.value}>
                  {
                    listing.balcony ? listing.balcony : '-'
                  }
                </Text>
              </View>
              {/* Private Entrance */}
              <View style={styles.detail}>
                <Text style={textStyles.label}>Private Entrance</Text>
                <Text style={textStyles.value}>-</Text>
              </View>
              {/* Exposure */}
              <View style={styles.detail}>
                <Text style={textStyles.label}>Exposure</Text>
                <Text style={textStyles.value}>
                  {
                    listing.exposure ? listing.exposure : '-'
                  }
                </Text>
              </View>
            </View>
          </View>
          {/* prices */}
          <View>
            <Text style={textStyles.title}>Price Details</Text>
            <View style={styles.valueContainer}>
              {/* Listing Price */}
              <View style={styles.detail}>
                <Text style={textStyles.label}>Listing Price</Text>
                <Text style={textStyles.value}>
                  {
                    listing.listing_price ?
                      <NumberFormat
                        value={listing.listing_price}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={0}
                        displayType={'text'} /> :
                      '-'
                  }
                </Text>
              </View>
              {/* Purchased Price */}
              <View style={styles.detail}>
                <Text style={textStyles.label}>Purchased Price</Text>
                <Text style={textStyles.value}>
                  {
                    listing.purchase_price ?
                      <NumberFormat
                        value={listing.purchase_price}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={0}
                        displayType={'text'} /> :
                      '-'
                  }
                </Text>
              </View>
              {/* Current Deposit */}
              <View style={styles.detail}>
                <Text style={textStyles.label}>Current Deposit</Text>
                <Text style={textStyles.value}>
                  {
                    listing.current_deposit ?
                      <NumberFormat
                        value={listing.current_deposit}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={0}
                        displayType={'text'} /> :
                      '-'
                  }
                </Text>
              </View>
              {/* Future Deposit */}
              <View style={styles.detail}>
                <Text style={textStyles.label}>Future Deposit</Text>
                <Text style={textStyles.value}>
                  {
                    listing.future_deposit ?
                      <NumberFormat
                        value={listing.future_deposit}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={0}
                        displayType={'text'} /> :
                      '-'
                  }
                </Text>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
      <View style={styles.footer}>
        <View style={{ display: 'flex', gap: 4 }}>
          <Text style={textStyles.title}>{listing.agent.name}</Text>
          <Text style={textStyles.label}>{listing.agent.phone}</Text>
        </View>
        <TouchableOpacity style={styles.button} onPress={() => { }}>
          <Text style={{ color: '#fff' }}>Send Message</Text>
        </TouchableOpacity>
      </View>
      <Overlay isVisible={expandDescription} onBackdropPress={toggleOverlay} fullScreen={true} overlayStyle={{ backgroundColor: '#f9f9f9', padding: 0 }} animationType={'slide'}>
        <ScrollView style={{ backgroundColor: '#f9f9f9' }}>
          <View style={{ display: 'flex', alignItems: 'flex-end', paddingVertical: 15, paddingHorizontal: 20, borderColor: '#dadadd', borderWidth: 1 }}>
            <TouchableOpacity onPress={toggleOverlay}>
              <Icon name='close' size={14} color='#1c1c1e' />
            </TouchableOpacity>
          </View>
          <View style={{ padding: 20 }}>
            <Text style={{ fontSize: 21, fontWeight: '600', color: '#1c1c1e', marginBottom: 24 }}>About this unit</Text>
            <Text style={{ fontSize: 15, color: '#1c1c1e' }}>{listing.description}</Text>
          </View>
        </ScrollView>
      </Overlay>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: height,
    backgroundColor: '#f9f9f9',
  },
  header: {
    height: 56,
    borderBottomWidth: 1,
    borderBottomColor: '#dadadd',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 84,
    borderTopWidth: 1,
    borderTopColor: '#dadadd',
    padding: 20,
    backgroundColor: '#fff'
  },
  button: {
    width: '40%',
    height: '100%',
    backgroundColor: '#378c79',
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 15,
    fontWeight: '600',
  },
  back: {
    position: 'absolute',
    left: 20
  },
  icon: {
    marginRight: 8
  },
  rooms: {
    flexDirection: 'row',
    display: 'flex',
    gap: 58,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
    paddingVertical: 4
  },
  gap: {
    display: 'flex',
    gap: 10,
  },
  padding: {
    padding: 20
  },
  marginHorizontal: {
    marginHorizontal: 20
  },
  paddingVertical: {
    paddingVertical: 20
  },
  borderBottom: {
    borderBottomColor: '#dadadd',
    borderBottomWidth: 1,
    borderStyle: 'solid',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  priceCard: {
    backgroundColor: '#378c79',
    paddingHorizontal: 20,
    paddingVertical: 24,
    gap: 24,
    borderRadius: 8,
    marginTop: 24,
    marginBottom: 40
  },
  valueContainer: {
    marginTop: 24,
    gap: 12
  },
  detail: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#dadadd',
  }
});

const textStyles = StyleSheet.create({
  title: {
    fontSize: 17,
    fontWeight: '600',
    color: '#1c1c1e',
  },
  label: {
    color: '#97979a',
    fontSize: 14,
    fontWeight: '600',
  },
  value: {
    color: '#1c1c1e',
    fontSize: 15,
    fontWeight: '600',
  },
});

export default ListingScreen;
