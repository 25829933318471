import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import thunk from 'redux-thunk';
import { listenerMiddleware } from './listener';
import accountReducer from './reducers/accountReducer';
import icomoonReducer from './reducers/icomoonReducer';
import listingReducer from './reducers/listingReducer';
import projectReducer from './reducers/projectReducer';
import treedReducer from './reducers/treedReducer';

export const store = configureStore({
    reducer: {
        IcoMoon: icomoonReducer,
        projects: projectReducer,
        listings: listingReducer,
        account: accountReducer,
        treed: treedReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(thunk).prepend(listenerMiddleware.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
