import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import getIcoMoonFont from '../assets/icomoon/icomoon';

interface RoleProps {
  createClient: () => void;
  createAgent: () => void;
}

const Role: React.FC<RoleProps> = ({ createClient, createAgent }) => {
  const { Icon, fontsLoaded } = getIcoMoonFont();

  if (!fontsLoaded) {
    return (<View><Text>Loading...</Text></View>)
  }

  return (
    <View>
      <Text style={textStyles.title}>Who you are</Text>
      <TouchableOpacity onPress={createClient} style={styles.option}>
        <View style={styles.icon}><Icon name='userLogo' size={22} color={'#378c79'} /></View>
        <Text style={textStyles.label}>I'm searching listings</Text>
        <Icon style={textStyles.icon} name='arrowRight' size={15} color={'#1c1c1e'} />
      </TouchableOpacity>
      <TouchableOpacity onPress={createAgent} style={styles.option}>
        <View style={styles.icon}><Icon name='agentLogo' size={22} color={'#378c79'} /></View>
        <Text style={textStyles.label}>I'm an agent</Text>
        <Icon style={textStyles.icon} name='arrowRight' size={15} color={'#1c1c1e'} />
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  option: {
    width: '100%',
    height: 80,
    paddingHorizontal: 20,
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 8,
    border: '1px solid #dadadd',
    marginVertical: 12,
    flexDirection: 'row',
    gap: 16
  },
  icon: {
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    color: "#378c79"
  }
});

const textStyles = StyleSheet.create({
  title: {
    fontSize: 21,
    fontWeight: '600',
    color: '#1c1c1e',
    marginBottom: 20,
  },
  label: {
    fontSize: 15,
    fontWeight: '600',
    color: '#1c1c1e'
  },
  icon: {
    position: 'absolute',
    right: 20
  }
});

export default Role;
