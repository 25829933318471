import React, { useState } from 'react';
import { Platform, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import getIcoMoonFont from '../assets/icomoon/icomoon';
import { setAccountEmail, setAccountPassword } from '../redux/actions';
import { useAppDispatch, useAppSelector } from '../redux/store';

interface Props {
  toggleEmailEdit: () => void;
}

const ChangeEmail: React.FC<Props> = ({ toggleEmailEdit }) => {
  const account = useAppSelector(state => state.account);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorPassword, setErrorPassword] = useState('')
  const dispatch = useAppDispatch();

  const { Icon, fontsLoaded } = getIcoMoonFont();

  const onSubmit = () => {

  }

  if (!fontsLoaded) {
    return (<View><Text>Loading...</Text></View>)
  }
  return (
    <ScrollView style={{ backgroundColor: '#f9f9f9' }}>
      <View style={{ display: 'flex', alignItems: 'flex-end', paddingVertical: 15, paddingHorizontal: 20, borderColor: '#dadadd', borderWidth: 1 }}>
        <TouchableOpacity onPress={toggleEmailEdit}>
          <Icon name='close' size={14} color='#1c1c1e' />
        </TouchableOpacity>
      </View>
      <View style={{ padding: 20 }}>
        <Text style={textStyles.title}>Change email</Text>
        <TextInput
          autoFocus={true}
          placeholder={'Email address'}
          placeholderTextColor="#97979a"
          style={
            StyleSheet.flatten([
              styles.input,
              { borderColor: errorMessage ? '#b82e14' : '#dadadd' },
              Platform.OS == 'web' && { outlineStyle: 'none' }
            ])
          }
          onChangeText={(t) => {
            if (errorMessage) {
              setErrorMessage('');
            }
            dispatch(setAccountEmail(t));
          }}
          onSubmitEditing={onSubmit}
          value={account.email}
        />

        <TextInput
          placeholder={'Password'}
          placeholderTextColor="#97979a"
          secureTextEntry={true}
          style={
            StyleSheet.flatten([
              styles.input,
              { borderColor: errorPassword ? '#b82e14' : '#dadadd' },
              Platform.OS == 'web' && { outlineStyle: 'none' }
            ])
          }
          onChangeText={(e) => {
            if (errorPassword) setErrorPassword('');
            dispatch(setAccountPassword(e));
          }}
          onSubmitEditing={onSubmit}
          value={account.password}
        />
        {
          account.password === '' && errorPassword !== '' &&
          <Text style={textStyles.error}>{errorPassword}</Text>
        }
      </View>
      <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', paddingHorizontal: 20 }}>
        <TouchableOpacity>
          <View style={StyleSheet.flatten([styles.button, { backgroundColor: '#f9f9f9' }])}>
            <Text style={StyleSheet.flatten([textStyles.button, { color: '#1c1c1e' }])}>Cancel</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity>
          <View style={styles.button}>
            <Text style={textStyles.button}>Submit</Text>
          </View>
        </TouchableOpacity>
      </View>
    </ScrollView>
  )
};

const styles = StyleSheet.create({
  input: {
    width: '100%',
    height: 60,
    paddingHorizontal: 16,
    backgroundColor: '#fff',
    borderRadius: 4,
    border: '1px solid #dadadd',
    marginVertical: 8,
    fontSize: 17,
    color: '#1c1c1e',
  },
  button: {
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    paddingHorizontal: 50,
    backgroundColor: '#378c79',
    borderRadius: 4,
  }
});

const textStyles = StyleSheet.create({
  title: {
    fontSize: 21,
    fontWeight: '600',
    color: '#1c1c1e',
    marginBottom: 24,
  },
  input: {
    fontSize: 17,
    color: '#97979a',
  },
  button: {
    fontSize: 16,
    fontWeight: '600',
    color: '#fff'
  },
  error: {
    fontSize: 15,
    color: '#b82e14',
    paddingBottom: 8
  }
});
export default ChangeEmail;
