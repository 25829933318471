import { Slider } from "@miblanchard/react-native-slider";
import * as React from 'react';
import { StyleSheet, View } from 'react-native';

const DEFAULT_VALUE = 0.2;

interface SliderContainerProps {
  children: React.ReactElement;
  value?: Array<number>;
  setValue?: React.Dispatch<React.SetStateAction<number[]>>;
  trackMarks?: Array<number>;
}

const SliderContainer: React.FC<SliderContainerProps> = ({ children, value, setValue, trackMarks }) => {

  let renderTrackMarkComponent: React.ReactNode;

  const renderChildren = () => {
    return React.Children.map(
      children,
      (child: React.ReactElement) => {
        if (!!child && child.type === Slider) {
          return React.cloneElement(child, {
            onValueChange: setValue,
            renderTrackMarkComponent,
            trackMarks,
            value,
          });
        }

        return child;
      },
    );
  };

  return (
    <View style={styles.sliderContainer}>
      {renderChildren()}
    </View>
  );
};

export default SliderContainer;

const borderWidth = 4;

const trackMarkStyles = StyleSheet.create({
  activeMark: {
    borderColor: 'red',
    borderWidth,
    left: -borderWidth / 2,
  },
  inactiveMark: {
    borderColor: 'grey',
    borderWidth,
    left: -borderWidth / 2,
  },
});

const styles = StyleSheet.create({
  sliderContainer: {
    width: '90%',
    alignSelf: 'center',
  },
});