import React, { useState } from 'react';
import { StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { setAccountBrokerage, setAccountFirstName, setAccountLastName, setAccountLicense, setAccountPassword, setAccountPhone } from '../redux/actions';
import { useAppDispatch, useAppSelector } from '../redux/store';

interface AgentProps {
  toRoleSelection: () => void;
  createAccount: () => void;
};

const Agent: React.FC<AgentProps> = ({ toRoleSelection, createAccount }) => {
  const [errorFirstName, setErrorFirstName] = useState('')
  const [errorLastName, setErrorLastName] = useState('')
  const [errorPassword, setErrorPassword] = useState('')
  const [errorBrokerage, setErrorBrokerage] = useState('')
  const [errorPhone, setErrorPhone] = useState('')
  const [errorLicense, setErrorLicense] = useState('')
  const account = useAppSelector(state => state.account);
  const dispatch = useAppDispatch();

  const process = () => {
    if (account.firstName === '' || account.lastName === '' || account.password === '' || account.license === '' || account.brokerage === '' || account.phone === '') {
      setErrorFirstName('Enter first name');
      setErrorLastName('Enter last name');
      setErrorPassword('Enter password');
      setErrorLicense('Enter license number');
      setErrorBrokerage('Enter brokerage name');
      setErrorPhone('Enter contact number');
    } else {
      createAccount();
    }
  };

  const goBack = () => {
    dispatch(setAccountFirstName(''));
    dispatch(setAccountLastName(''));
    dispatch(setAccountPassword(''));
    dispatch(setAccountBrokerage(''));
    dispatch(setAccountLicense(''));
    dispatch(setAccountPhone(''));
    toRoleSelection();
  };

  return (
    <View>
      <Text style={textStyles.title}>treedrealestate@gmail.com</Text>
      <Text style={textStyles.subTitle}>I'm searching listings</Text>
      <TextInput
        autoFocus={true}
        placeholder={'First name'}
        placeholderTextColor="#97979a"
        style={styles.input}
        onChangeText={(e) => dispatch(setAccountFirstName(e))}
        value={account.firstName}
      />
      {
        account.firstName === '' && errorFirstName !== '' &&
        <Text style={textStyles.error}>{errorFirstName}</Text>
      }
      <TextInput
        placeholder={'Last name'}
        placeholderTextColor="#97979a"
        style={styles.input}
        onChangeText={(e) => dispatch(setAccountLastName(e))}
        value={account.lastName}
      />
      {
        account.lastName === '' && errorLastName !== '' &&
        <Text style={textStyles.error}>{errorLastName}</Text>
      }
      <TextInput
        placeholder={'License Number'}
        placeholderTextColor="#97979a"
        style={styles.input}
        onChangeText={(e) => dispatch(setAccountLicense(e))}
        value={account.license}
      />
      {
        account.license === '' && errorLicense !== '' &&
        <Text style={textStyles.error}>{errorLicense}</Text>
      }
      <TextInput
        placeholder={'Brokerage Name'}
        placeholderTextColor="#97979a"
        style={styles.input}
        onChangeText={(e) => dispatch(setAccountBrokerage(e))}
        value={account.brokerage}
      />
      {
        account.brokerage === '' && errorBrokerage !== '' &&
        <Text style={textStyles.error}>{errorLicense}</Text>
      }
      <TextInput
        placeholder={'Contact Number'}
        placeholderTextColor="#97979a"
        style={styles.input}
        onChangeText={(e) => dispatch(setAccountPhone(e))}
        value={account.phone}
      />
      {
        account.phone === '' && errorPhone !== '' &&
        <Text style={textStyles.error}>{errorLicense}</Text>
      }
      <TextInput
        placeholder={'Password'}
        placeholderTextColor="#97979a"
        secureTextEntry={true}
        style={styles.input}
        onChangeText={(e) => dispatch(setAccountPassword(e))}
        value={account.password}
      />
      {
        account.password === '' && errorPassword !== '' &&
        <Text style={textStyles.error}>{errorPassword}</Text>
      }
      <View style={styles.login}>
        <TouchableOpacity onPress={goBack} style={StyleSheet.flatten([styles.button, { backgroundColor: 'transparent' }])}>
          <Text style={StyleSheet.flatten([textStyles.button, { color: '#1c1c1e' }])}>Back</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={process} style={styles.button}>
          <Text style={textStyles.button}>Next</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  input: {
    width: '100%',
    height: 60,
    paddingHorizontal: 16,
    backgroundColor: '#fff',
    borderRadius: 4,
    border: '1px solid #dadadd',
    marginVertical: 8
  },
  login: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  button: {
    width: 100,
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-end',
    backgroundColor: '#378c79',
    borderRadius: 4,
    marginTop: 32,
  },
});

const textStyles = StyleSheet.create({
  title: {
    fontSize: 21,
    fontWeight: '600',
    color: '#1c1c1e'
  },
  subTitle: {
    fontSize: 15,
    color: '#97979a',
    marginTop: 12,
    marginBottom: 24
  },
  input: {
    fontSize: 17,
    color: '#97979a',
  },
  button: {
    fontSize: 16,
    fontWeight: '600',
    color: '#fff'
  },
  label: {
    fontSize: 14,
    color: '#1c1c1e'
  },
  error: {
    fontSize: 15,
    color: '#b82e14',
    paddingBottom: 8
  }
});

export default Agent;
