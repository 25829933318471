import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

interface Project {
	id: number;
	address: string;
	builders: string;
	city: string;
	lat: number;
	lng: number;
	name: string;
	postal_code: string;
	province: string;
	type: string;
	listingCount: number;
	minPrice: number;
	maxPrice: number;
	image: string;
}

interface ProjectState {
	projects: Project[],
	selectedProject: number,
};

const initialState = { selectedProject: 481 } as ProjectState

export const fetchProjects = createAsyncThunk
	(
		'project/fetchProjects',
		async (thunkAPI) => {
			const url = `https://www.treedrealestate.com/api/projects/mobile/`;
			try {
				const response = await axios.get(url);
				return response.data;
			} catch (err) {
				console.log(err)
			}
		}
	)


const projectSlice = createSlice({
	name: 'project',
	initialState,
	reducers: {
		selectProject: (state, action: PayloadAction<number>) => {
			state.selectedProject = action.payload
		},
		deselectProject: (state) => {
			state.selectedProject = -1
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchProjects.fulfilled, (state, action) => {
			state.projects = action.payload;
		})
	},
});

export const { selectProject, deselectProject } = projectSlice.actions;
export default projectSlice.reducer;