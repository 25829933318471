import axios from 'axios';
//@ts-ignore
import Cookies from 'js-cookie';

export const RefreshToken = async () => {
  const refreshTime = parseInt(Cookies.get('treedTimestamp'), 10);

  if (Date.now() - refreshTime < 205000) {
    return;
  }

  try {
    const token = Cookies.get('treedRefreshToken');
    if (!token) {
      throw 'Need to login first';
    }
    Cookies.set("treedTimestamp", Date.now(), { secure: true });
    const url = `${axios.defaults.baseURL}/api/token/refresh/`;
    const data = { refresh: token };
    const resp = await axios.post(url, data);
    console.log(resp);
    // @ts-ignore
    axios.defaults.headers['Authorization'] = `Bearer ${resp.data.access}`;
  } catch (err) {
    console.log(err);
  }
};
