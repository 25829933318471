import { Overlay } from "@rneui/themed";
import React, { useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import getIcoMoonFont from '../assets/icomoon/icomoon';
import { useAppSelector } from '../redux/store';
import Account from "./Account";

interface Props {
  toMain: () => void;
}

const Header: React.FC<Props> = ({ toMain }) => {
  const [expandAccount, setExpandAccount] = useState(false);
  const currentUser = useAppSelector(state => state.account.currentUser);
  const { Icon, fontsLoaded } = getIcoMoonFont();

  if (!fontsLoaded) {
    return (<View><Text>Loading...</Text></View>)
  }

  const toggleOverlay = () => {
    setExpandAccount(!expandAccount);
  }

  return (
    <>
      <View style={styles.header}>
        <TouchableOpacity onPress={toMain}>
          <Icon name='TreedLogoText' size={24} color="#378c79" />
        </TouchableOpacity>
        {
          currentUser ?
            <TouchableOpacity onPress={toggleOverlay}>
              <View style={{ width: 24, height: 24, backgroundColor: '#1c1c1e', borderRadius: 12, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 13, fontWeight: '600', color: '#fff' }}>{currentUser.charAt(0).toUpperCase()}</Text>
              </View>
            </TouchableOpacity> :
            <Icon name='user' size={24} color="#1c1c1e" />
        }
      </View>
      <Overlay isVisible={expandAccount} onBackdropPress={toggleOverlay} fullScreen={true} overlayStyle={{ backgroundColor: '#f9f9f9', padding: 0 }} animationType={'slide'}>
        <Account toggleOverlay={toggleOverlay} />
      </Overlay>
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingHorizontal: 20,
    paddingTop: 20,
  }
});

export default Header;
